<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <!--<b-breadcrumb-item :to="{ name: 'agreements' }">{{$t('label_contacts')}}</b-breadcrumb-item>-->
                <b-breadcrumb-item active>{{$t(curPageLabel)}}</b-breadcrumb-item>
            </div>
        </div>


        <!--***************************************FILTER***************************************-->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>
                    {{ $t('label_filters') }}
                </h5>
            </b-card-header>
        <b-card-body >

            <b-row v-if="activeTab == TASK_PREFIX + '_TAB'">
                <b-col
                        cols="12"
                        md="3"
                        class=" date-range-col"
                >
                    <label>{{ $t('label_executed_date') }}</label>

                    <div class="date-range-filter mb-2">
                        <custom-date-picker
                                class="date--picker no-label"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"
                                :value="taskFilterData.deadline_at_from"
                                @input="(val) => taskFilterData.deadline_at_from = val"

                        >
                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                        </custom-date-picker>

                        <div class="date-separator" />
                        <custom-date-picker
                                class="date--picker no-label"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"
                                :value="taskFilterData.deadline_at_to"
                                @input="(val) => taskFilterData.deadline_at_to = val"

                        >
                            <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                        </custom-date-picker>

                    </div>

                </b-col>
                <b-col
                        cols="12"
                        md="3"
                        class=" mb-1 "
                >
                    <label>{{ $t('label_task_type') }}</label>

                    <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            v-model="taskFilterData.task_type_id"
                            :options="taskTypes"
                            class="w-100"
                            :placeholder="$t('label_select')"
                            :reduce="val => val.id"
                    >
                        <template v-slot:option="option">
                            <!--<span :class="option.icon"></span>-->
                            <span class="">{{ option.translation_index ? $t(option.translation_index) : option.name}}</span>
                        </template>
                        <template v-slot:selected-option="option">
                            <!--<span :class="option.icon"></span>-->
                            <span class="">{{option.translation_index ? $t(option.translation_index) : option.name}}</span>
                        </template>
                    </v-select>

                </b-col>

                <b-col
                        cols="12"
                        md="6"
                        sm="12"
                        class=" mb-1 "
                >
                   <div> <label>{{ $t('label_task_types') }}</label></div>
                    <b-form-radio v-for="item in taskSources"
                                  class="mr-1 mb-1"
                                  v-model="taskFilterData.task_source"
                                  button
                                  size="sm"
                                  button-variant="outline-primary"
                                  :value="item.alias"
                    >
                        <span style="white-space: nowrap;">{{ $t(item.label)}}</span>
                    </b-form-radio>

                </b-col>

            </b-row>
            <b-row v-if="activeTab == MEETING_PREFIX + '_TAB'">

                <b-col cols="12" md="3" class=" mb-1 ">
                        <label>{{ $t('label_consultant') }}</label>
                        <infinity-scroll
                                v-model="meetingFilterData.consultant_id"
                                selected-prop="id"
                                url="/consultant"
                                :multiple="false"
                                :placeholder="$t('label_select')"

                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>
                </b-col>
                <b-col cols="12" md="3" class=" mb-1 ">

                        <label>{{ $t('label_solicitor') }}</label>
                        <infinity-scroll
                                v-model="meetingFilterData.solicitor_id"
                                selected-prop="id"
                                url="/solicitor"
                                :multiple="false"
                                :placeholder="$t('label_select')"

                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                </b-col>
                <b-col cols="12" md="6" class=" mb-1 ">

                       <label>{{ $t('label_meeting_type') }}</label>
                       <v-select
                               :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                               v-model="meetingFilterData.meeting_types"
                               :options="meetingTypes"
                               :multiple="true"
                               class=""
                               :placeholder="$t('label_select')"
                               :reduce="val => val.id"
                       >
                           <template v-slot:option="option">
                               <!--<span :class="option.icon"></span>-->
                               <span class="">{{ $t(option.translation_index? $t(option.translation_index) : option.name)}}</span>
                           </template>
                           <template v-slot:selected-option="option">
                               <!--<span :class="option.icon"></span>-->
                               <span class="">{{ $t(option.translation_index? $t(option.translation_index) : option.name)}}</span>
                           </template>
                       </v-select>

                </b-col>


            </b-row>
        </b-card-body>
        </b-card>
        <!--***************************************FILTER***************************************-->

        <b-card no-body>

            <b-card-body>
                <b-tabs content-class="mt-1" >

                    <b-tab :ref="TASK_PREFIX + '_TAB'"
                           :show-all="true"
                           :is-deal="is_deal"
                           :module-item="moduleItem"
                           v-if="$can('view', 'agreement.tab_'+TASK_PREFIX+'s')"
                           @click="curPageLabel = $t('label_tasks'); activeTab = TASK_PREFIX + '_TAB'"
                           :title="$t('label_tasks')"
                           lazy
                    >

                        <tab-tasks
                                :extra-filter-data="taskFilterData"
                                :show-all="true"
                                :module-item="moduleItem"
                                :ref="TASK_PREFIX + '_CONTENT_TAB'"
                                @edit-item="editedTask = $event;"
                                @add-item="editedTask = false;"
                                :is-deal="is_deal"
                        ></tab-tasks>

                    </b-tab>


                    <b-tab
                            :ref="MEETING_PREFIX + '_TAB'"
                            v-if="$can('view', 'contact.tab_'+MEETING_PREFIX+'s')"
                            @click="curPageLabel = $t('label_meetings'); activeTab = MEETING_PREFIX + '_TAB'"
                            :title="$t('label_meetings')"
                            lazy
                    >

                        <tab-meetings
                                :extra-filter-data="meetingFilterData"
                                :show-all="true"
                                :ref="MEETING_PREFIX + '_CONTENT_TAB'"
                                @edit-item="editedMeeting = $event; activeTab = MEETING_PREFIX + '_TAB'"
                                  :module-item="moduleItem"
                        ></tab-meetings>

                    </b-tab>


                </b-tabs>
            </b-card-body>
        </b-card>

        <process-task-modal
                :is-registry-agrm-task="isRegistryAgrmTask"
                :show-all="true"
                :edited-item="editedTask"
                @edit-item="isRegistryAgrmTask = !!$event.pivot_id"
                @item-edited="$refs[TASK_PREFIX + '_CONTENT_TAB'] ? $refs[TASK_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[TASK_PREFIX + '_CONTENT_TAB'] ? $refs[TASK_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-task-modal>

        <process-meeting-modal
                :show-all="true"
                :edited-item="editedMeeting"
                @item-added="$refs[MEETING_PREFIX + '_CONTENT_TAB'] ? $refs[MEETING_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-edited="$refs[MEETING_PREFIX + '_CONTENT_TAB'] ? $refs[MEETING_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-meeting-modal>

    </div>
</template>

<script>

    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar, BFormCheckbox, BFormRadio

    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import processTaskModal from './../agreement/modals/processTaskModal'
    import processMeetingModal from './../contact/modals/processMeetingModal'

    import tabMeetings from './../contact/tabs/tabMeetings'
    import tabTasks from './../agreement/tabs/tabTasks'
    import  infinityScroll from '@/views/components/infinityScroll'


    import {TASK_PREFIX, MEETING_PREFIX} from './moduleHelper'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar, BFormCheckbox, BFormRadio,

            processTaskModal,
            processMeetingModal,
            tabTasks,
            tabMeetings,
            infinityScroll,
            vSelect
        },
        data() {
            return {
                module_id:null,
                TASK_PREFIX,
                MEETING_PREFIX,

                is_deal: true,

                activeTab: TASK_PREFIX + '_TAB',
                editedTask:false,
                editedMeeting:false,
                curPageLabel: 'label_tasks',
                moduleItem: false,
                isRegistryAgrmTask: false,

                taskFilterData:{
                    task_type_id:null,
                    task_creator_id:null,
                    task_executor_id:null,
                    task_source:null,
                    deadline_at_from: null,
                    deadline_at_to: null,
                },

                meetingFilterData:{
                    meeting_types:[],
                    consultant_id: null
                },

                taskSources:[
                    {alias:'only_agrms', label:'label_agreements'},
                    {alias:'only_deals', label:'label_deals'},
                    {alias:'only_contract_registry', label:'label_agreement_register'},
                ],
                taskTypes: [],
                meetingTypes:[],

                defaultExecutor: false
            }
        },


        created() {
            this.async('get', '/select_options', {params:{options:['agrm_task_types', 'meeting_types', 'registry_agrm_task_types']}}, function(res){
                // this.taskSources = this.taskSources.concat(res.data.registry_agrm_task_types);
                this.taskTypes = res.data.agrm_task_types;
                this.meetingTypes  =  res.data.meeting_types;
                this.meetingFilterData.meeting_types = this.meetingTypes.pluck('id');
            });
            let authUser = this.authUser();
            this.taskFilterData.task_executor_id = authUser.id;
            this.defaultExecutor = {id:authUser.id, name: authUser.name};
        },


    }
</script>

